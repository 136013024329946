import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import PreviewQuizzes from '../components/previewQuizzes'
import RelatedArticles from '../components/relatedArticles'
import Comments from '../components/common/comments'

const QuizPage = () => {
    return (
        <>
            <GatsbySeo
                title="Vinquiz"
                description="Vill du testa dina kunskaper om vin är våra quiz ett perfekt hjälpmedel! Välj quiz efter ämne och svårighetsgrad och glöm inte att skapa ett konto om du vill spara dina resultat."
                openGraph={{
                    title: 'Vinquiz',
                    description:
                        'Vill du testa dina kunskaper om vin är våra quiz ett perfekt hjälpmedel! Välj quiz efter ämne och svårighetsgrad och glöm inte att skapa ett konto om du vill spara dina resultat.',
                }}
            />
            <Hero
                heading="Vinquiz"
                body="Vill du testa dina kunskaper om vin är våra quiz ett perfekt hjälpmedel! <br/><br/> Välj quiz efter ämne och svårighetsgrad och glöm inte att skapa ett konto om du vill spara dina resultat."
            />
            <PreviewQuizzes />
            <Comments contentfulPageId={'quiz'} />
            <RelatedArticles headline="Vill du lära dig något nytt?" />
        </>
    )
}

export default QuizPage
