import React, { useState, useEffect, useContext } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { FirebaseContext } from './firebase'
import Tags from './tags'
import SectionContainer from './layout/sectionContainer'
import * as previewQuizzesStyling from './previewQuizzes.module.scss'

const PreviewQuizzes = () => {
    const [quizResults, setQuizResults] = useState([])
    const [loadingQuizResults, setLoadingQuizResults] = useState(true)

    const { firebase, loading, user } = useContext(FirebaseContext)

    useEffect(() => {
        if (firebase) {
            if (user) {
                firebase
                    .getUserQuizScores({
                        username: user.username,
                    })
                    .then((querySnapshot) => {
                        const quizResults = []
                        querySnapshot.forEach((doc) => {
                            const data = doc.data()
                            quizResults[data.contentfulQuizId] = {
                                score: data.score,
                                numberOfQuestions: data.numberOfQuestions,
                            }
                        })
                        setQuizResults(quizResults)
                        setLoadingQuizResults(false)
                    })
            } else {
                setQuizResults([])
            }
        }
    }, [firebase, user])

    const data = useStaticQuery(graphql`
        query {
            allContentfulQuiz(sort: { fields: [createdAt], order: DESC }) {
                edges {
                    node {
                        contentful_id
                        id
                        createdAt(formatString: "D MMMM YYYY", locale: "sv")
                        title
                        difficulty
                        description
                        topics
                        slug
                    }
                }
            }
        }
    `)

    const allQuizzes = data.allContentfulQuiz.edges

    const notLoggedIn = !loading && !user

    return (
        <SectionContainer
            title={'Quiz'}
            subtitle={'Testa dina egna kunskaper.'}
        >
            {allQuizzes.map(({ node: quiz }) => (
                <Link
                    key={quiz.id}
                    to={quiz.slug}
                    className={previewQuizzesStyling.quizWrapper}
                >
                    <div className={previewQuizzesStyling.textWrapper}>
                        <h3 className={previewQuizzesStyling.title}>
                            {quiz.title}
                        </h3>
                        <p className={previewQuizzesStyling.difficulty}>
                            Svårighetsgrad: {quiz.difficulty}
                        </p>
                        <p>Skapat {quiz.createdAt}</p>
                        {!loading &&
                            !!user &&
                            !loadingQuizResults &&
                            !!quizResults[quiz.contentful_id] && (
                                <p className={previewQuizzesStyling.result}>
                                    Ditt resultat:{' '}
                                    {quizResults[quiz.contentful_id].score} av{' '}
                                    {
                                        quizResults[quiz.contentful_id]
                                            .numberOfQuestions
                                    }{' '}
                                    rätt.
                                </p>
                            )}
                        {!loading &&
                            !!user &&
                            !loadingQuizResults &&
                            !quizResults[quiz.contentful_id] && (
                                <p className={previewQuizzesStyling.result}>
                                    Du har inget resultat för detta quiz.
                                </p>
                            )}
                        {notLoggedIn && (
                            <p className={previewQuizzesStyling.result}>
                                Logga in för att se dina sparade resultat.
                            </p>
                        )}
                    </div>
                    <div>
                        <p>{quiz.description}</p>
                        <Tags tags={quiz.topics} />
                    </div>
                </Link>
            ))}
        </SectionContainer>
    )
}

PreviewQuizzes.propTypes = {}

export default PreviewQuizzes
